@import url('http://fonts.cdnfonts.com/css/nunito-sans');

@media only screen and (max-width: 993px) {
    .error-500, .error-503, .error-404, .error-400, .error-403, .login {
        display: block !important;
        position: relative !important; }
}

.common-img-bg {
    background-size: cover;
    background: url('fondo_unirex_fd.jpg') no-repeat center center fixed;
    height: 100%; 
}

.input-group {
    margin-bottom: 1.25em; }

.signin-card .auth-box button i, .signup-card .auth-box button i, .login-card .auth-box button i {
    padding: 5px 0; }
  
  .login-card.auth-body {
    width: 450px; }
  
  .signin-card.auth-body {
    width: 550px; }
  
  .signup-card.auth-body {
    width: 650px;
    margin-bottom: 70px; }
  
  .login-card {
    font-family: "Nunito Sans", sans-serif; 
    border-radius: 30px;
    position: relative;
    display: block; 
    }
    .login-card h1 {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center; }
  
  .login {
    text-align: left; }
    .login ul li label {
      color: #757575;
      font-weight: 600;
      letter-spacing: 1px; }
    .login h3 {
      color: #666666;
      font-size: 24px;
      font-weight: 600;
      margin-top: 20px; }
  
  .header .login-card {
    margin-top: 30px; }
  
  .social-login {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 30px; }
    .social-login .btn {
      margin-left: 5px;
      margin-right: 5px; }
  
  .pcoded .pcoded-header.auth-header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    box-shadow: 0 -1px 15px -4px rgba(0, 0, 0, 0.79); }
  
  .login-card .card-footer {
    background: #fff; }
  .login-card h1 {
    color: #1b8bf9; }
  .login-card .forget {
    border-bottom: 1px solid #fff;
    font-size: 24px;
    line-height: 0.6; }
  .login-card h3 {
    color: #666666;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px; }
  
  .btn-signup .btn-md,
  .btn-singin1 .btn-md,
  .btn-singin2 .btn-md {
    font-size: 22px;
    font-weight: 400;
    width: 400px; }
  
    .form-control{
        display:block;
        width:100%;
        padding:.625rem 1rem;
        font-size:0.8rem;
        font-weight:600;
        line-height:1.2;
        color:#31374a;
        background-color:#fff;
        background-clip:padding-box;
        border:1px solid #cbd0dd;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        border-radius:.375rem !important;
        -webkit-box-shadow:inset 0 1px 2px rgba(0,0,0,0);
        box-shadow:inset 0 1px 2px rgba(0,0,0,0);
        -webkit-transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        -o-transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
      }
  
  .form-group-main .form-group {
    margin: 30px 0; }
  
  .login-card .unlock,
  .login-card .btn-forgot {
    text-align: center; }
  
  .imgs-screen .img-circle {
    padding: 4px;
    border: 1px solid #E6E6E4; }
  
  .sign-in-with {
    font-weight: 600;
    margin: 24px auto 16px;
    position: relative;
    text-align: center; }
    .sign-in-with .text {
      display: inline-block;
      padding: 0 8px;
      position: relative;
      vertical-align: baseline;
      z-index: 9999; }
      .sign-in-with .text:after {
        left: 100%; }
      .sign-in-with .text:before {
        right: 100%; }
      .sign-in-with .text:after, .sign-in-with .text:before {
        border-top: 1px solid rgba(0, 0, 0, 0.28);
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        width: 60px; }
  
  .error-500,
  .error-503,
  .error-404,
  .error-400,
  .error-403,
  .login {
    align-items: center;
    background-color: #2196F3;
    display: flex;
    height: 100%;
    position: fixed;
    text-align: center;
    width: 100%;
    min-height: 100vh; }
  
  .auth-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 20px 0 0 0;
    padding: 20px;
    box-shadow: 0 2px 18px -2px black; }
    .auth-box .confirm h3 {
      color: #4680ff;
      font-size: 34px; }
    .auth-box i.icofont-check-circled {
      font-size: 42px; }
  
  .btn-facebook {
    background-color: #3c5a99;
    border-color: #3c5a99;
    border-radius: 2px;
    color: #fff;
    cursor: pointer; }
  
  .btn-twitter {
    background-color: #41abe1;
    border-color: #41abe1;
    border-radius: 2px;
    color: #fff;
    cursor: pointer; }
  
  .btn-google-plus,
  .btn-linkedin,
  .btn-pinterest {
    border-radius: 2px; }
  
  .pos-relative.login {
    position: relative !important; }
  
  .footer {
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 9; }
    .footer p {
      margin-bottom: 0;
      padding: 18px;
      width: 100%; }
  
  .login-card-modal {
    margin: 0 auto; }
  
  .login.login-modal-main {
    background-color: #fff; }
  
  .mixed-form .tabbed-modal .nav-link h6 {
    padding: 10px 0; }
  
  .mixed-form .tabbed-modal .nav-link {
    background-color: #f5f5f5;
    border-radius: 0;
    color: #888; }
  
  .mixed-form .tabbed-modal .nav-link.active {
    background-color: transparent;
    border-color: transparent;
    color: #303548; }
  
  .mixed-form .modal-body {
    padding: 0; }
  
  .mixed-form .auth-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 10px;
    padding: 10px; }
    .mixed-form .auth-box h3 {
      color: #666666;
      font-size: 22px;
      font-weight: 600;
      margin-top: 20px; }
    .mixed-form .auth-box i.icofont-check-circled {
      font-size: 42px; }
  
  .auth-box-main .navbar-nav li {
    color: #fff;
    line-height: 40px; }
  
  .login.offline-404 {
    background-color: #f3f3f3;
    display: flex !important; }
    .login.offline-404 .auth-body {
      margin-top: -100px; }
    .login.offline-404 h1 {
      color: #2c3e50;
      font-size: 160px;
      font-weight: 600;
      letter-spacing: 5px;
      text-shadow: 3px -2px 4px rgba(128, 128, 128, 0.57); }
    .login.offline-404 h2 {
      color: #aaa;
      font-weight: 600;
      margin-bottom: 40px;
      margin-top: 30px; }
    @media only screen and (max-width: 768px) {
      .login.offline-404 {
        padding-top: 100px; }
        .login.offline-404 h1 {
          font-size: 60px; }
        .login.offline-404 h2 {
          margin-bottom: 15px;
          margin-top: 15px; } }

.checkbox-fade, .checkbox-zoom {
  display: inline-block;
  margin-right: 15px; }
  .checkbox-fade label, .checkbox-zoom label {
    line-height: 20px; }
    .checkbox-fade label input[type="checkbox"],
    .checkbox-fade label input[type="radio"], .checkbox-zoom label input[type="checkbox"],
    .checkbox-zoom label input[type="radio"] {
      display: none; }
      .checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon,
      .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon, .checkbox-zoom label input[type="checkbox"]:checked + .cr > .cr-icon,
      .checkbox-zoom label input[type="radio"]:checked + .cr > .cr-icon {
        transform: scale(1) rotateZ(0deg);
        opacity: 1; }
      .checkbox-fade label input[type="checkbox"] + .cr > .cr-icon,
      .checkbox-fade label input[type="radio"] + .cr > .cr-icon, .checkbox-zoom label input[type="checkbox"] + .cr > .cr-icon,
      .checkbox-zoom label input[type="radio"] + .cr > .cr-icon {
        transform: scale(3) rotateZ(-20deg);
        opacity: 0;
        transition: all 0.3s ease-in; }
    .checkbox-fade label:after, .checkbox-zoom label:after {
      content: '';
      display: table;
      clear: both; }
  .checkbox-fade.fade-in-disable .cr,
  .checkbox-fade.fade-in-disable label, .checkbox-zoom.fade-in-disable .cr,
  .checkbox-zoom.fade-in-disable label {
    color: #ccc;
    cursor: no-drop; }
  .checkbox-fade .cr, .checkbox-zoom .cr {
    border-radius: 0;
    border: 2px solid #4680ff;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 20px;
    margin-right: 0.5em;
    position: relative;
    width: 20px; }
    .checkbox-fade .cr .cr-icon, .checkbox-zoom .cr .cr-icon {
      color: #4680ff;
      font-size: 0.8em;
      left: 0;
      line-height: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%; }


/* cheb */

.checkbox-fade, .checkbox-zoom {
    display: inline-block;
    margin-right: 15px; }
    .checkbox-fade label, .checkbox-zoom label {
      line-height: 20px; }
      .checkbox-fade label input[type="checkbox"],
      .checkbox-fade label input[type="radio"], .checkbox-zoom label input[type="checkbox"],
      .checkbox-zoom label input[type="radio"] {
        display: none; }
        .checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon,
        .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon, .checkbox-zoom label input[type="checkbox"]:checked + .cr > .cr-icon,
        .checkbox-zoom label input[type="radio"]:checked + .cr > .cr-icon {
          transform: scale(1) rotateZ(0deg);
          opacity: 1; }
        .checkbox-fade label input[type="checkbox"] + .cr > .cr-icon,
        .checkbox-fade label input[type="radio"] + .cr > .cr-icon, .checkbox-zoom label input[type="checkbox"] + .cr > .cr-icon,
        .checkbox-zoom label input[type="radio"] + .cr > .cr-icon {
          transform: scale(3) rotateZ(-20deg);
          opacity: 0;
          transition: all 0.3s ease-in; }
      .checkbox-fade label:after, .checkbox-zoom label:after {
        content: '';
        display: table;
        clear: both; }
    .checkbox-fade.fade-in-disable .cr,
    .checkbox-fade.fade-in-disable label, .checkbox-zoom.fade-in-disable .cr,
    .checkbox-zoom.fade-in-disable label {
      color: #ccc;
      cursor: no-drop; }
    .checkbox-fade .cr, .checkbox-zoom .cr {
      border-radius: 0;
      border: 2px solid #4680ff;
      cursor: pointer;
      display: inline-block;
      float: left;
      height: 20px;
      margin-right: 0.5em;
      position: relative;
      width: 20px; }
      .checkbox-fade .cr .cr-icon, .checkbox-zoom .cr .cr-icon {
        color: #4680ff;
        font-size: 0.8em;
        left: 0;
        line-height: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 50%; }
  
        .checkbox-fade label {
            line-height: 20px; }
            .checkbox-fade label input[type="checkbox"],
            .checkbox-fade label input[type="radio"] {
              display: none; }
              .checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon,
              .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon {
                transform: scale(1) rotateZ(0deg);
                opacity: 1; }
              .checkbox-fade label input[type="checkbox"] + .cr > .cr-icon,
              .checkbox-fade label input[type="radio"] + .cr > .cr-icon {
                transform: scale(3) rotateZ(-20deg);
                opacity: 0;
                transition: all 0.3s ease-in; }
          
          .checkbox-zoom label {
            line-height: 20px; }
            .checkbox-zoom label input[type="checkbox"],
            .checkbox-zoom label input[type="radio"] {
              display: none; }
              .checkbox-zoom label input[type="checkbox"]:checked + .cr > .cr-icon,
              .checkbox-zoom label input[type="radio"]:checked + .cr > .cr-icon {
                transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
                opacity: 1; }
              .checkbox-zoom label input[type="checkbox"] + .cr > .cr-icon,
              .checkbox-zoom label input[type="radio"] + .cr > .cr-icon {
                transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
                opacity: 0;
                transition: all 0.3s ease-in; }
          
          .checkbox-fade.fade-in-primary .cr, .checkbox-fade.zoom-primary .cr, .checkbox-zoom.fade-in-primary .cr, .checkbox-zoom.zoom-primary .cr {
            border: 2px solid #4680ff; }
            .checkbox-fade.fade-in-primary .cr .cr-icon, .checkbox-fade.zoom-primary .cr .cr-icon, .checkbox-zoom.fade-in-primary .cr .cr-icon, .checkbox-zoom.zoom-primary .cr .cr-icon {
              color: #4680ff; }
          
          .checkbox-fade.fade-in-warning .cr, .checkbox-fade.zoom-warning .cr, .checkbox-zoom.fade-in-warning .cr, .checkbox-zoom.zoom-warning .cr {
            border: 2px solid #FFB64D; }
            .checkbox-fade.fade-in-warning .cr .cr-icon, .checkbox-fade.zoom-warning .cr .cr-icon, .checkbox-zoom.fade-in-warning .cr .cr-icon, .checkbox-zoom.zoom-warning .cr .cr-icon {
              color: #FFB64D; }
          
          .checkbox-fade.fade-in-default .cr, .checkbox-fade.zoom-default .cr, .checkbox-zoom.fade-in-default .cr, .checkbox-zoom.zoom-default .cr {
            border: 2px solid #e0e0e0; }
            .checkbox-fade.fade-in-default .cr .cr-icon, .checkbox-fade.zoom-default .cr .cr-icon, .checkbox-zoom.fade-in-default .cr .cr-icon, .checkbox-zoom.zoom-default .cr .cr-icon {
              color: #e0e0e0; }
          
          .checkbox-fade.fade-in-danger .cr, .checkbox-fade.zoom-danger .cr, .checkbox-zoom.fade-in-danger .cr, .checkbox-zoom.zoom-danger .cr {
            border: 2px solid #FC6180; }
            .checkbox-fade.fade-in-danger .cr .cr-icon, .checkbox-fade.zoom-danger .cr .cr-icon, .checkbox-zoom.fade-in-danger .cr .cr-icon, .checkbox-zoom.zoom-danger .cr .cr-icon {
              color: #FC6180; }
          
          .checkbox-fade.fade-in-success .cr, .checkbox-fade.zoom-success .cr, .checkbox-zoom.fade-in-success .cr, .checkbox-zoom.zoom-success .cr {
            border: 2px solid #93BE52; }
            .checkbox-fade.fade-in-success .cr .cr-icon, .checkbox-fade.zoom-success .cr .cr-icon, .checkbox-zoom.fade-in-success .cr .cr-icon, .checkbox-zoom.zoom-success .cr .cr-icon {
              color: #93BE52; }
          
          .checkbox-fade.fade-in-inverse .cr, .checkbox-fade.zoom-inverse .cr, .checkbox-zoom.fade-in-inverse .cr, .checkbox-zoom.zoom-inverse .cr {
            border: 2px solid #303548; }
            .checkbox-fade.fade-in-inverse .cr .cr-icon, .checkbox-fade.zoom-inverse .cr .cr-icon, .checkbox-zoom.fade-in-inverse .cr .cr-icon, .checkbox-zoom.zoom-inverse .cr .cr-icon {
              color: #303548; }
          
          .checkbox-fade.fade-in-info .cr, .checkbox-fade.zoom-info .cr, .checkbox-zoom.fade-in-info .cr, .checkbox-zoom.zoom-info .cr {
            border: 2px solid #62d1f3; }
            .checkbox-fade.fade-in-info .cr .cr-icon, .checkbox-fade.zoom-info .cr .cr-icon, .checkbox-zoom.fade-in-info .cr .cr-icon, .checkbox-zoom.zoom-info .cr .cr-icon {
              color: #62d1f3; }
          
          /*===== Color-checkbox css start =====*/
          .checkbox-color {
            display: inline-block;
            margin-right: 20px;
            cursor: pointer; }
            .checkbox-color label {
              display: inline-block;
              position: relative;
              padding-left: 10px;
              line-height: 20px; }
              .checkbox-color label::before {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0;
                width: 20px;
                height: 20px;
                left: 0;
                right: 0;
                text-align: center;
                margin-left: -20px;
                border: 1px solid #ccc;
                border-radius: 0;
                background-color: #fff;
                transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
              .checkbox-color label::after {
                display: inline-block;
                position: absolute;
                width: 16px;
                height: 16px;
                left: -1px;
                top: 0;
                margin-left: -17px;
                padding-left: 3px;
                padding-top: 1px;
                font-size: 11px;
                color: #fff; }
            .checkbox-color input[type="checkbox"] {
              opacity: 0; }
              .checkbox-color input[type="checkbox"]:focus + label::before {
                outline: thin dotted;
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: -2px; }
              .checkbox-color input[type="checkbox"]:checked + label::after {
                font-family: 'FontAwesome';
                content: "\f00c"; }
              .checkbox-color input[type="checkbox"]:disabled + label {
                opacity: 0.65; }
                .checkbox-color input[type="checkbox"]:disabled + label::before {
                  background-color: #eee;
                  cursor: not-allowed; }
            .checkbox-color.checkbox-circle label::before {
              border-radius: 50%; }
            .checkbox-color.checkbox-inline {
              margin-top: 0; }
          
          .checkbox-danger input[type="checkbox"]:checked + label::after,
          .checkbox-info input[type="checkbox"]:checked + label::after,
          .checkbox-primary input[type="checkbox"]:checked + label::after,
          .checkbox-success input[type="checkbox"]:checked + label::after,
          .checkbox-warning input[type="checkbox"]:checked + label::after {
            color: #fff; }
          
          .checkbox-primary input[type="checkbox"]:checked + label::before {
            background-color: #2196f3;
            border-color: #2196f3; }
          
          .checkbox-primary input[type="checkbox"]:checked + label::before {
            background-color: #4680ff; }
          
          .checkbox-warning input[type="checkbox"]:checked + label::before {
            background-color: #FFB64D; }
          
          .checkbox-default input[type="checkbox"]:checked + label::before {
            background-color: #e0e0e0; }
          
          .checkbox-danger input[type="checkbox"]:checked + label::before {
            background-color: #FC6180; }
          
          .checkbox-success input[type="checkbox"]:checked + label::before {
            background-color: #93BE52; }
          
          .checkbox-inverse input[type="checkbox"]:checked + label::before {
            background-color: #303548; }
          
          .checkbox-info input[type="checkbox"]:checked + label::before {
            background-color: #62d1f3; }
          
          /*===== Tag input css start =====*/
          .bootstrap-tagsinput {
            border: 1px solid #4680ff;
            line-height: 30px;
            border-radius: 2px; }
            .bootstrap-tagsinput .tag {
              padding: 6px;
              border-radius: 2px; }

.text-inverse {
color: #303548 !important; }              
.m-b-0 {
    margin-bottom: 0px;
}

