@import url('http://fonts.cdnfonts.com/css/open-sans');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;

/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cursor{
    cursor: pointer;
}
.form-group label{
     font-weight: bold !important;
     color: #111 !important;
     margin-bottom: 0px !important;
}
.form-control{
  font-family: 'Open Sans', sans-serif !important;
}
.logo_sm{
  width: 64px;
}
.logoslider{
    width: 80px;
    margin: auto;
    display: block;
}
.sidebar .nav .nav-item.nav-profile .nav-link{
    padding-top: 6px !important;
}
.padding-0{
    margin-bottom: 0px !important;
}
.color_bodega{
    color: #ff5730;
    font-weight: bold;
}
.color_enruta{
    color: #ff0854 !important;
    font-weight: bold;
}
.color_novedadcli{
    color: #00cff4;
    font-weight: bold;
}
.color_reexpedicion{
    color: #0033c4;
    font-weight: bold;
}
.color_solucion{
    color: #116502;
    font-weight: bold;
}
.color_devolucion{
    color: #f60808;
    font-weight: bold;
}
.color_nvedadcli{
  color : #DB1DDE;
  font-weight: bold;
}
.tabla_opcion{
  vertical-align:bottom;
}
.inframemax{
  position:fixed; 
  top:0 !important; 
  left:0 !important; 
  bottom:0 !important; 
  right:0; 
  height: 100vh;        /* Viewport-relative units */
    width: 100vw; 
}
.cursor-auto{
  cursor: auto !important;
}
.btnflotante_s{  
  right: 0;  
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50%; /* Centra verticalmente */
  right: 20px; /* Puedes ajustar esto según tu diseño */
  transform: translateY(50%); /* Alinea el centro vertical */
  z-index: 1000;
}
.colorverder{
  color:#00d284;
}
.textoliq{
  color: #f60808;
  font-size: 17px;
  font-weight: bold;
}
/*
.form-control{
  border: 1px solid #868686 !important;
  font-size: 0.9075rem !important;
  font-weight: 400 !important;
  line-height: 1.53 !important;
  color: #697a8d !important;
}
*/
.form-control-sm{
  height: 31px !important;
}
select.form-control{
    padding: 0.3075rem 0.75rem !important;
}
.mdi::before {
  font-size: 24px;
  line-height: 14px;
}
.btn .mdi::before {
  position: relative;
  top: 4px;
}
.btn-xs .mdi::before {
  font-size: 18px;
  top: 3px;
}
.btn-sm .mdi::before {
  font-size: 18px;
  top: 3px;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

.formato_poblaciones{
    margin-top: -10px !important;
}
.texto_poblaciones{
    font-size: 0.875rem !important;
    line-height: 1 !important;
    font-weight: bold !important;
}
/* tablas */

table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

table * {
  position: relative;
}
table td, table th {
  padding-left: 8px;
}
table thead tr {
  height: 40px;
  background-image: linear-gradient(to right top, #1b70f0, #006bd8, #0065bf, #005ea5, #0f568c);
}
table thead tr th {
    color: #fff !important;
}
table tbody tr {
  /*height: 50px;*/
}
table tbody tr:last-child {
  border: 0;
}
table td, table th {
  text-align: left;
}
table td.l, table th.l {
  text-align: right;
}
table td.c, table th.c {
  text-align: center;
}
table td.r, table th.r {
  text-align: center;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text{
    margin-left: 0px !important;
}

.table100-head th{
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: bold !important;
  color: #fff !important;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

tbody tr {
  font-family: 'Open Sans';
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:hover {
  color: #555555;
  background-color: #f5f5f5;
  cursor: pointer;
}

.btnflotante{ 
    position: absolute;
    right: 0;
    margin-top: 100px;
}
.btnflotante2{ 
    position: absolute;
    right: 0;
    margin-top: 160px;
}

#settings-trigger{
  display: none !important;
}
/* dropzone */

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
